.MuiSwitch-root {
  padding: 8px !important;
}

.MuiSwitch-track {
  /* background: #ED1C25 !important; */
  opacity: 1 !important;
  border-radius: 30px !important;

}

/* CSS */
.order-box {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  /* padding: 20px; */
  border-radius: 5px;
  box-shadow: 0 9px 9px rgba(19, 19, 19, 0.1);
  background: #f7f7f7;
}

.booking-item-box {
  border-right: 1px solid #eee;
  /* padding: 10px 0; */
 }
 .booking-item-box:nth-child(4){
  border-right: 0;
 }
   
.booking-item {
  display: flex;
  align-items: center;
}

.booking-item img {
  width: 100px;
  height: 100%;
  margin-right: 20px;
}

.image{
  padding-top: 0px !important;
}


.booking-item .ml-4 {
  width: calc(100% - 120px);
  /* Adjust the width accordingly */
}

.booking-item .text-black {
  font-size: 16px;
  font-weight: bold;
}

.booking-item .text-gray-400 {
  font-size: 14px;
}

/* Add more styles as needed */
@media (max-width: 426px) {

  .font-class{
    font-size: 14px;
  }
  .booking-item-box {
    border-right: none;
    padding: 0px;
  }

}

.moving-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  margin-top: 25px;
  /* Adjust the margin as needed */
}

.moving-text {
  display: inline-block;
  padding-left: 100%;
  /* Start outside the container */
  animation: marquee 15s linear infinite;
}